.app-name {
    text-decoration: none;
    margin: 0 0 0 10px;
}

.nav {
    display: flex;
    position: fixed;
    width: 100%;
    align-items: center;
    background-color: var(--background-color);
    z-index: 9999;
}

.nav > * {
    margin: 0 10px;
}

.logo {
    display: flex;
    justify-content: left;
    cursor: pointer;
}

.nav-links {
    margin: 0 0 0 auto;
}

.menu-toggle {
    display: none;
}

.footer a:hover {
    color: var(--link-hovered-color);
}

.nav ul {
    display: flex;
    justify-content: right;
    padding: 20px 10px 20px;
    list-style: none;
}

.nav li {
    margin: 0 15px;
}

.nav li a {
    transition: color 0.2s ease-in-out;
}

.nav li .logout-button:hover {
    color: var(--danger-link-hovered-color);
}

.nav li a:hover {
    color: var(--link-hovered-color);
}

.nav a {
    display: flex;
    color: white;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: bold;
}

.nav-links i {
    margin: 3px 5px;
}

.menu-toggle i {
    transform: translateY(-7px)
}

@media only screen and (max-width: 1055px) {
    .nav {
        height: 63px;
    }

    .menu-toggle {
        background-color: rgba(0, 0, 0, 0);
        border: none;
        display: block;
        font-size: 1.8rem;
        color: white;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        margin: 0 0 15px;
        z-index: 2;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 50px;
        width: 100%;
        height: 100%;
        background-color: var(--background-color);
        transition: all 0.5s ease;
    }

    .nav-links ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .nav-links li {
        width: 100%;
        text-align: center;
    }

    .nav-links li a {
        padding: 25px 10px;
        width: 100%;
        border-bottom: 1px solid #ccc;
        text-align: center;
    }

    .nav-links.active {
        display: flex;
        flex-direction: column;
    }
}
