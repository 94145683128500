.container {
    display: flex;
    flex-direction: column;
    background-color: var(--main-section-background-color);
    color: white;
}

.main {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 90px 20px 50px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
}

.title {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
}

.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    border: 3px solid var(--default-input-border-color);
    color: white;
    background-color: var(--input-field-background-color);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: border-color 0.3s ease-out;
}

.input:focus {
    border-color: var(--error-color);
}

.label {
    display: block;
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    transition: color 0.3s ease-out;
}


/* normal button */

.button {
    background-color: var(--continue-button-color);
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    margin: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}

.button:hover {
    background-color: var(--continue-button-hovered-color);
}

.button:active {
    background-color: var(--continue-button-active-color);
}


/* processing button */

.button.processing {
    background-color: var(--button-processing-color);
    cursor: default;
}

.button.processing:hover {
    background-color: var(--button-processing-color-hovered);
}

.button.processing:active {
    background-color: var(--button-processing-color-active);
}

.spaced-button {
    margin-top: 30px;
}

.link {
    color: var(--continue-button-color);
    text-decoration: none;
}

.link:hover, .link-blue:hover {
    cursor: pointer;
}

.link-blue {
    color: var(--link-color);
}

.error {
    height: 2rem;
    width: 500px;
    text-align: center;
    margin-bottom: 0.5rem;
    color: var(--error-color);
    font-size: 1.2rem;
    font-weight: 700;
}

.notice {
    height: 5rem;
    width: 1000px;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--success-color);
    font-size: 1.2rem;
    font-weight: 700;
}

.checkboxes {
    margin: 5px 0 10px
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 230px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 8px;
    margin-bottom: 6px;
}

.checkbox-container label {
    font-size: 16px;
}

.info-entry {
    margin-top: 5px;
    font-size: 20px;
}

.info-entry i {
    cursor: pointer;
    color: white;
    margin-top: 5px;
    margin-left: 6px;
}

.danger-button {
    background-color: var(--cancel-button-color);
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}

.danger-button:hover {
    background-color: var(--cancel-button-hovered-color);
}

.danger-button:active {
    background-color: var(--cancel-button-active-color);
}

.info {
    max-width: 700px;
}

.block {
    text-align: center;
}

.text-input input[type="text"],
.text-input input[type="number"],
.text-input select,
.text-input textarea {
    color: white;
    background-color: var(--input-field-background-color);
    border: none;
    outline: none;
    outline-width: 0;
}

@media only screen and (max-width: 1000px) {
    .notice, .error {
        width: 700px;
    }
}

@media only screen and (max-width: 750px) {
    .notice {
        width: 500px;
        margin-bottom: 2rem;
    }

    .error {
        width: 500px;
    }
}

@media only screen and (max-width: 520px) {
    .notice {
        width: 300px;
        margin-bottom: 7rem;
    }

    .error {
        width: 300px;
    }

    .form > div {
        max-width: 70%;
    }

    .form > div > input {
        max-width: 100%;
    }
}
