.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dialog-text-color);
}

.content {
    background-color: rgb(24, 26, 27);
    border-radius: 0.55rem;
    padding: 1rem;
    height: auto;
	width: auto;
    max-width: 75%;
	min-width: 34vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
	overflow-wrap: break-word;
    z-index: 9999;
}

.dialog-info {
	width: 100%;
	margin: 0 15px 0 15px;
	text-align: center;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.actions > * {
	margin: 0 0.2rem 0 0.2rem;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dialog h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--dialog-text-color)
}

.dialog label, .dialog div label {
    color: var(--dialog-text-color)
}

.dialog p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: var(--dialog-text-color)
}

.dialog input[type="text"],
.dialog input[type="password"],
.dialog select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #dedede;
    border-radius: 0.25rem;
}

.dialog input[type="checkbox"] {
    margin-right: 0.5rem;
}


.dialog .content input[type="text"] {
	display: flex;
	flex-direction: column;
	width: 25rem;
}

.dialog label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #383838;
}

.dialog .submit-button {
    background-color: var(--continue-button-color);
    color: white;
    font-size: 1.2rem;
    padding: 10px 14px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}

.dialog .submit-button:hover {
    background-color: var(--continue-button-hovered-color);
}

.dialog .submit-button:active {
    background-color: var(--continue-button-active-color);
}

.dialog .submit-button.processing {
    background-color: var(--button-processing-color);
    cursor: default;
}

.dialog .submit-button.processing:hover {
    background-color: var(--button-processing-color-hovered);
}

.dialog .submit-button.processing:active {
    background-color: var(--button-processing-color-active);
}

.dialog .cancel-button {
    background-color: var(--cancel-button-color);
    color: white;
    font-size: 1.2rem;
    padding: 10px 14px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}

.dialog .cancel-button:hover {
    background-color: var(--cancel-button-hovered-color);
}

.dialog .cancel-button:active {
    background-color: var(--cancel-button-active-color);
}

.dialog-close {
    transform: rotate(45deg) translateX(5px) translateY(-5px);
    font-size: 20px;
    color: var(--dialog-cross-button-color);
    transition: color 0.1s ease-out;
    cursor: pointer;
}

.dialog-close:hover {
    color: var(--dialog-cross-button-hovered-color);
}

@media only screen and (max-width: 520px) {
	.content {
		max-width: 80%
	}
}
